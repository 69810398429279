import React from 'react'
import ReactDOM from 'react-dom'
import {HttpLink} from 'apollo-link-http'
import {ApolloClient} from 'apollo-client'
import {ApolloProvider} from '@apollo/react-hooks'
import {InMemoryCache} from 'apollo-cache-inmemory'

import App from './screens/App'
import * as serviceWorker from './serviceWorker'

import '@mhd/components-library/semantic/dist/semantic.css'

const cache = new InMemoryCache()
const link = new HttpLink({
  uri: '/graphql',
})
const client = new ApolloClient({
  cache,
  link,
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
