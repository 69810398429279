/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext, useState, useEffect} from 'react'
import {Redirect} from 'react-router'
import {useMutation, useLazyQuery} from '@apollo/react-hooks'
import {ApolloError} from 'apollo-client'
import {Semantic} from '@mhd/components-library'
import {GraphCoordinator} from '@app/models/cooordinatorModel'
import {START_SYNC} from '@app/graphql/coordinator.graphql'
import {AccountingContext} from '@app/context/accountingContext'
import SyncProgress from './components/syncProgress'
import {GraphIntegration} from '@app/models/integrationModel'
import {GET_INTEGRATION} from '@app/graphql/integration.graphql'
import {Paths, IntegrationStatus} from '@app/models/constants'
import alQbConnection from '@app/assets/images/app/alQbConnection.svg'
import ConnectionDetailsComp from '../ConfirmConnection/components/ConnectionDetails'
import * as styles from './styles'
import ErrorPage from '../Error'

const Home = (): JSX.Element => {
  const [focus, setFocus] = useState(true)

  const {
    activeIntegration,
    setLoading,
    setActiveIntegration,
    setCurrentUser,
    text,
  } = useContext(AccountingContext)
  const [errorMessage, setErrorMessage] = useState('')

  const checkFocusAndSetPolling = (
    interval: number,
    startPolling: Function,
    stopPolling: Function,
  ): void => {
    if (focus) {
      startPolling(interval)
    } else {
      stopPolling()
    }
  }

  const handleError = (error: ApolloError): void => {
    //this means call to coordinator failed, either network error or unauthorized issue
    if (error.message.includes('401')) {
      setErrorMessage(text.UnauthorizedTryRefresh())
    } else {
      setErrorMessage(error.message)
    }
  }

  const [startSync] = useMutation<GraphCoordinator>(START_SYNC, {
    onError: error => {
      handleError(error)
    },
  })

  const [getIntegration, {startPolling, stopPolling}] = useLazyQuery<
    GraphIntegration
  >(GET_INTEGRATION, {
    onCompleted: (data): void => {
      setLoading(false)
      if (data) {
        setActiveIntegration(data.getIntegration)
        setCurrentUser(data.getIntegration.currentUser)
        if (
          data.getIntegration.integrationStatusId ===
          IntegrationStatus.SyncInProgress
        ) {
          startPolling(500)
        } else {
          checkFocusAndSetPolling(3000, startPolling, stopPolling)
        }
      }
    },
    onError: (error: ApolloError): void => {
      setLoading(false)
      if (error.message.includes('401')) {
        setErrorMessage(text.UnauthorizedTryRefresh())
      } else {
        setErrorMessage(error.message)
      }
    },
    fetchPolicy: 'cache-and-network',
  })

  const triggerSync = async (): Promise<void> => {
    const result = await startSync({
      variables: {
        integrationId: activeIntegration && activeIntegration.integrationId,
        portalId:
          activeIntegration &&
          activeIntegration.currentUser &&
          activeIntegration.currentUser.portalId,
      },
    })

    if (result && result.data && result.data.startSync) {
      if (result.data.startSync.success) {
        getIntegration()
        startPolling(500)
      } else if (result.data.startSync.errorMessage) {
        //coordinator calls succeeded however it can have error message as a result
        setErrorMessage(result.data.startSync.errorMessage)
      }
    }
  }

  const handleActivity = (focus: boolean): void => {
    setFocus(focus)
    getIntegration()
  }

  useEffect((): void => {
    window.addEventListener('blur', () => handleActivity(false))
    window.addEventListener('focus', () => handleActivity(true))

    getIntegration()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const content = (
    <div>
      <SyncProgress onClick={triggerSync} />
      <Semantic.Header css={styles.subheaderCss}>
        Connection Details
      </Semantic.Header>
      <div css={styles.mainDivCss}>
        <div css={styles.gridDiv}>
          <div css={[styles.rowGridLeft, styles.rowGrid]}>
            <img alt="alQbConnection" src={alQbConnection} />
          </div>
          <ConnectionDetailsComp
            css={[styles.rowGridRight, styles.rowGrid]}
            showChangeAccountLink={false}
          />
        </div>
      </div>
    </div>
  )

  const Route = (): JSX.Element => {
    const statusId =
      (activeIntegration && activeIntegration.integrationStatusId) ||
      IntegrationStatus.NeedSetup
    switch (statusId) {
      case IntegrationStatus.NeedSetup:
        return <Redirect to={Paths.Setup} />
      case IntegrationStatus.NeedChartOfAccounts:
        return <Redirect to={Paths.ChartOfAccounts} />
      default:
        return content
    }
  }

  return (
    <div>{errorMessage ? <ErrorPage message={errorMessage} /> : Route()}</div>
  )
}

export default Home
