/** @jsx jsx */
import {jsx} from '@emotion/core'
import * as styles from '../../styles'
import Button from '@app/components/Button'
import {Semantic} from '@mhd/components-library'
import {Paths} from '@app/models/constants'

export interface PreferencesEditItemProps {
  title: string
  content: string
  type: 'chart' | 'connection'
}
const PreferencesEditItem = (props: PreferencesEditItemProps): JSX.Element => {
  return (
    <div css={[styles.mainDivCss, styles.paddingCss]}>
      <Semantic.Header as="h2" css={styles.titleCss}>
        {props.title}
      </Semantic.Header>
      <div css={styles.rowGrid}>
        <Semantic.Header as="h3" css={styles.textCss}>
          {props.content}
        </Semantic.Header>
        <div css={styles.buttonDiv}>
          <Button
            css={[styles.buttonCss]}
            onClick={(): void => {
              window.location.pathname =
                props.type === 'chart'
                  ? Paths.EditChartOfAccounts
                  : Paths.AccountConnection
            }}
          >
            Edit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PreferencesEditItem
