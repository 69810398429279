/** @jsx jsx */
import {jsx} from '@emotion/core'

const ErrorPage = (props: {message: string}): JSX.Element => {
  return (
    <div>
      <p css={{paddingTop: 20, textAlign: 'center'}}>An error occurred</p>
      <p css={{paddingTop: 20, textAlign: 'center'}}>
        Message: {props.message}
      </p>
    </div>
  )
}

export default ErrorPage
