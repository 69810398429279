import {useState} from 'react'
import axios, {AxiosError} from 'axios'
import {Settings} from '@app/models/settings'
import {IntegrationType} from '@app/models/constants'

interface UseUiSettingsInterface {
  get: () => Promise<{next: boolean; integrationType: number}>
  loading: boolean
  settings: Settings
  errorMessage: string
}

const useUiSettings = (): UseUiSettingsInterface => {
  const [settings, setSettings] = useState({
    envName: '',
    qbClientId: '',
    qbEnvironment: '',
    qbRedirectUri: '',
  })
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const handleError = (error: AxiosError): void => {
    setErrorMessage(error.message)
    setLoading(false)
  }

  const setTokenCookie = function(data: any): boolean {
    const cookieName = data.bmtJWTCookieName
    const paramName = data.bmtJWTParamName
    const regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)')
    const results = regex.exec(window.location.search)
    if (results) {
      window.document.cookie = `${cookieName}=${results[1]}`
      window.location.replace(window.location.origin)
      return false
    }
    return true
  }

  const getCookie = (cname: string): string | undefined => {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(window.document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return undefined
  }

  const getIntegrationType = function(data: any): number {
    const bmtCookie = getCookie(data.bmtJWTCookieName)
    if (data.mhdIntegration) {
      return IntegrationType.MHD
    } else if (bmtCookie) {
      return IntegrationType.BMT
    } else {
      return IntegrationType.None
    }
  }

  const get = async (): Promise<{next: boolean; integrationType: number}> => {
    let next = false
    let integrationType = IntegrationType.None
    try {
      const uiSettings = await axios.get('/ui-settings')
      setSettings(uiSettings.data)
      next = setTokenCookie(uiSettings.data)
      integrationType = getIntegrationType(uiSettings.data)
      setLoading(false)
    } catch (error) {
      handleError(error)
    } finally {
      return {next, integrationType}
    }
  }

  return {get, loading, settings, errorMessage}
}

export default useUiSettings
