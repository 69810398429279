import {css} from '@emotion/core'

export const mainDivCss = css`
  position: relative;
  left: 50%;
  margin-left: -420px;
  margin-top: 96px;
  height: 1141px;
  width: 838px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
`

export const topBlockCss = css`
  position: relative;
  box-sizing: border-box;
  height: 130px;
  width: 837px;
  border-bottom: 1px solid #d6d6d6;
`

export const headerCss = css`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  color: #2a2a2a;
  font-family: Bitter;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
`

export const ovalCss = css`
  position: relative;
  top: 80px;
  left: 50%;
  transform: translate(-50%);
  height: 100px;
  width: 100px;
  background-color: #ffffff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  z-index: 10;
`

export const circleCheckCss = css`
  height: 40px;
  width: 40px;
  background-color: #048540;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
`

export const instructionsCss = css`
  height: 132px;
  width: 708px;
  color: #626262;
  font-family: Rubik;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 89.5px;
  margin-left: 65px;
`

export const greenlineCss = css`
  float: left;
  height: 6px;
  width: 418px;
  background-color: #048540;
  margin: 0px;
`

export const graylineCss = css`
  float: right;
  height: 6px;
  width: 418px;
  background-color: #eaeaea;
  margin: 0px;
`
export const blueTextCss = css`
  color: #007db8;
  display: inline-block;
`
