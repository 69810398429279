/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useQuery} from '@apollo/react-hooks'
import {GraphQuickbooks} from '@app/models/quickbooksModel'
import {GET_ACCOUNTS} from '@app/graphql/quickbooks.graphql'
import {map, filter} from 'lodash'
import AccountDropDown from '../AccountDropDown'
import Button from '@app/components/Button'
import {Paths, IntegrationStatus} from '@app/models/constants'
import {
  SAVE_CHART_OF_ACCOUNTS,
  SET_STATUS,
} from '@app/graphql/integration.graphql'
import {useMutation} from '@apollo/react-hooks'
import {GraphIntegration} from '@app/models/integrationModel'
import Loading from '@mhd/components-library/dist/components/Structure/Loading'
import {AccountSelection, SelectionRequired} from '@app/models/accountSelection'
import * as styles from './styles'

const AccountSettings = (props: {
  setError: Function
  setAccountSelection: Function
  selectionRequired: SelectionRequired
  setSelectionRequired: Function
  currentValues: AccountSelection
  type?: string
}): JSX.Element => {
  const {data, loading} = useQuery<GraphQuickbooks>(GET_ACCOUNTS, {
    onError: error => {
      props.setError(error && error.message)
    },
    variables: {
      param: {
        accountType:
          "('Income', 'Expense', 'Other Expense', 'Cost of Goods Sold', 'Accounts Receivable', 'Other Current Asset')",
      },
    },
  })

  const redirectHome = (): void => {
    window.location.pathname = Paths.Home
  }

  const redirectPreferences = (): void => {
    window.location.pathname = Paths.Preferences
  }

  const [setChartOfAccounts] = useMutation<GraphIntegration>(
    SAVE_CHART_OF_ACCOUNTS,
  )

  const [setIntegrationStatus] = useMutation<GraphIntegration>(SET_STATUS)

  const redirectConfirmConnection = async (): Promise<void> => {
    await setIntegrationStatus({
      variables: {
        statusChangeParam: {
          integrationStatusId: IntegrationStatus.NeedSetup,
        },
      },
    })
    window.location.replace(window.location.origin + Paths.ConfirmConnection)
  }

  const updateChartOfAccounts = async (): Promise<void> => {
    if (!props.currentValues.productIncome) {
      props.setSelectionRequired((prevState: SelectionRequired) => ({
        ...prevState,
        productIncomeRequired: true,
      }))
    }

    if (!props.currentValues.productExpense) {
      props.setSelectionRequired((prevState: SelectionRequired) => ({
        ...prevState,
        productExpenseRequired: true,
      }))
    }

    if (!props.currentValues.productAsset) {
      props.setSelectionRequired((prevState: SelectionRequired) => ({
        ...prevState,
        productAssetRequired: true,
      }))
    }

    if (!props.currentValues.serviceExpense) {
      props.setSelectionRequired((prevState: SelectionRequired) => ({
        ...prevState,
        serviceExpenseRequired: true,
      }))
    }

    if (!props.currentValues.serviceIncome) {
      props.setSelectionRequired((prevState: SelectionRequired) => ({
        ...prevState,
        serviceIncomeRequired: true,
      }))
    }

    if (!props.currentValues.accountsReceivable) {
      props.setSelectionRequired((prevState: SelectionRequired) => ({
        ...prevState,
        accountsReceivableRequired: true,
      }))
    }

    if (
      props.currentValues.productIncome &&
      props.currentValues.productExpense &&
      props.currentValues.productAsset &&
      props.currentValues.serviceIncome &&
      props.currentValues.serviceExpense &&
      props.currentValues.accountsReceivable
    ) {
      await setChartOfAccounts({
        variables: {
          chartOfAccounts: props.currentValues,
        },
      })
      if (props.type === 'edit') {
        redirectPreferences()
      } else {
        redirectHome()
      }
    }
  }

  const content = (
    <div>
      <div>
        <div css={styles.titleCss}>Products</div>
        <div css={styles.groupCss}>
          <p css={styles.textCss}>
            Which income account should we use to record revenue from{' '}
            <b>Product Sales</b>?
          </p>
          <div css={styles.dropdownCss}>
            <AccountDropDown
              label="Product Sales"
              placeholder="Select income account..."
              required={props.selectionRequired.productIncomeRequired}
              value={props.currentValues.productIncome}
              values={map(
                data &&
                  filter(data.getChartOfAccounts, {
                    accountType: 'Income',
                    accountSubType: 'SalesOfProductIncome',
                  }),
                account => {
                  return {text: account.name, value: account.id}
                },
              )}
              onChange={(event, data): void => {
                props.setAccountSelection((prevState: AccountSelection) => ({
                  ...prevState,
                  productIncome: data.value,
                }))
                if (data.value) {
                  props.setSelectionRequired(
                    (prevState: SelectionRequired) => ({
                      ...prevState,
                      productIncomeRequired: false,
                    }),
                  )
                }
              }}
            />
          </div>
        </div>
        <div css={styles.groupCss}>
          <p css={styles.textCss}>
            Which expense account should we use to record your{' '}
            <b>Cost of Goods Sold</b>?
          </p>
          <div css={styles.dropdownCss}>
            <AccountDropDown
              label="Cost of Goods Sold"
              placeholder="Select expense account..."
              required={props.selectionRequired.productExpenseRequired}
              value={props.currentValues.productExpense}
              values={map(
                data &&
                  filter(data.getChartOfAccounts, {
                    accountType: 'Cost of Goods Sold',
                  }),
                account => {
                  return {text: account.name, value: account.id}
                },
              )}
              onChange={(event, data): void => {
                props.setAccountSelection((prevState: AccountSelection) => ({
                  ...prevState,
                  productExpense: data.value,
                }))
                if (data.value) {
                  props.setSelectionRequired(
                    (prevState: SelectionRequired) => ({
                      ...prevState,
                      productExpenseRequired: false,
                    }),
                  )
                }
              }}
            />
          </div>
        </div>
        <div css={styles.groupCss}>
          <p css={styles.textCss}>
            Which asset account should we use to record <b>Inventory</b>?
          </p>
          <div css={styles.dropdownCss}>
            <AccountDropDown
              label="Inventory"
              placeholder="Select asset account..."
              required={props.selectionRequired.productAssetRequired}
              value={props.currentValues.productAsset}
              values={map(
                data &&
                  filter(data.getChartOfAccounts, {
                    accountType: 'Other Current Asset',
                    accountSubType: 'Inventory',
                  }),
                account => {
                  return {text: account.name, value: account.id}
                },
              )}
              onChange={(event, data): void => {
                props.setAccountSelection((prevState: AccountSelection) => ({
                  ...prevState,
                  productAsset: data.value,
                }))
                if (data.value) {
                  props.setSelectionRequired(
                    (prevState: SelectionRequired) => ({
                      ...prevState,
                      productAssetRequired: false,
                    }),
                  )
                }
              }}
            />
          </div>
        </div>
        <hr css={styles.lineCss} />
        <div css={styles.titleCss}>Services</div>
        <div css={styles.groupCss}>
          <p css={styles.textCss}>
            Which income account should we use to record revenue from{' '}
            <b>Service Sales</b>?
          </p>
          <div css={styles.dropdownCss}>
            <AccountDropDown
              label="Service Sales"
              placeholder="Select income account..."
              required={props.selectionRequired.serviceIncomeRequired}
              value={props.currentValues.serviceIncome}
              values={map(
                data &&
                  filter(data.getChartOfAccounts, {
                    accountType: 'Income',
                  }),
                account => {
                  return {text: account.name, value: account.id}
                },
              )}
              onChange={(event, data): void => {
                props.setAccountSelection((prevState: AccountSelection) => ({
                  ...prevState,
                  serviceIncome: data.value,
                }))
                if (data.value) {
                  props.setSelectionRequired(
                    (prevState: SelectionRequired) => ({
                      ...prevState,
                      serviceIncomeRequired: false,
                    }),
                  )
                }
              }}
            />
          </div>
        </div>
        <div css={styles.groupCss}>
          <p css={styles.textCss}>
            Which expense account should we use to record your{' '}
            <b>Service Expenses</b>?
          </p>
          <div css={styles.dropdownCss}>
            <AccountDropDown
              label="Service Expenses"
              placeholder="Select expense account..."
              required={props.selectionRequired.serviceExpenseRequired}
              value={props.currentValues.serviceExpense}
              values={map(
                data &&
                  filter(data.getChartOfAccounts, item => {
                    return (
                      item.accountType === 'Expense' ||
                      item.accountType === 'Other Expense' ||
                      item.accountType === 'Cost of Goods Sold'
                    )
                  }),
                account => {
                  return {text: account.name, value: account.id}
                },
              )}
              onChange={(event, data): void => {
                props.setAccountSelection((prevState: AccountSelection) => ({
                  ...prevState,
                  serviceExpense: data.value,
                }))
                if (data.value) {
                  props.setSelectionRequired(
                    (prevState: SelectionRequired) => ({
                      ...prevState,
                      serviceExpenseRequired: false,
                    }),
                  )
                }
              }}
            />
          </div>
        </div>
        <hr css={styles.lineCss} />
        <div css={styles.titleCss}>Invoices & Payments</div>
        <div css={styles.groupCss}>
          <p css={styles.textCss}>
            Which A/R account should we use to record{' '}
            <b>Invoices and Payments</b>?
          </p>
          <div css={styles.dropdownCss}>
            <AccountDropDown
              label="Invoices & Payments"
              placeholder="Select A/R account..."
              required={props.selectionRequired.accountsReceivableRequired}
              value={props.currentValues.accountsReceivable}
              values={map(
                data &&
                  filter(data.getChartOfAccounts, {
                    accountType: 'Accounts Receivable',
                  }),
                account => {
                  return {text: account.name, value: account.id}
                },
              )}
              onChange={(event, data): void => {
                props.setAccountSelection((prevState: AccountSelection) => ({
                  ...prevState,
                  accountsReceivable: data.value,
                }))
                if (data.value) {
                  props.setSelectionRequired(
                    (prevState: SelectionRequired) => ({
                      ...prevState,
                      accountsReceivableRequired: false,
                    }),
                  )
                }
              }}
            />
          </div>
        </div>
      </div>
      <div css={styles.buttonContainer}>
        <div css={styles.buttonGroupCss}>
          <Button
            css={styles.finishCss}
            onClick={(): Promise<void> => updateChartOfAccounts()}
          >
            {props.type === 'edit' ? 'Save Changes' : 'Finish'}
          </Button>
          {props.type === 'edit' ? (
            <div css={styles.cancelCss}>
              <a href={Paths.Preferences}>Cancel</a>
            </div>
          ) : (
            <Button
              css={styles.backCss}
              onClick={(): Promise<void> => redirectConfirmConnection()}
            >
              Back
            </Button>
          )}
        </div>
      </div>
    </div>
  )

  return loading ? <Loading /> : content
}

export default AccountSettings
