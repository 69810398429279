import {css} from '@emotion/core'
import backgroundImage from '@app/assets/images/app/background-pattern.svg'

export const containerBackgroundCss = css`
  padding-top: 5px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-size: 1em;
  background-image: url(${backgroundImage});
`

export const containerCss = css`
  padding-top: 5px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-size: 1em;
`
export const sideBarIconCss = css`
  display: inline !important;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  margin-left: 20px;
  vertical-align: -4px !important;
`

export const sideMenuItemCss = css`
  height: 50px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  &:before {
    display: none !important;
    width: 0 !important;
  }
`
export const selectedTabCss = css`
  color: #048540 !important;
`

export const disabledTabCss = css`
  color: #cccccc !important;
`
export const menuTabCss = css`
  color: #929292 !important;
`

export const firstMenuItemCss = css`
  margin-top: 20px;
  ${sideMenuItemCss}
`

export const pushableCss = css`
  overflow-x: initial !important;
`

export const pusherCss = css`
  overflow: initial !important;
  overflow-x: initial !important;
  margin-left: -40px;
`
