/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Dropdown, DropdownProps} from 'semantic-ui-react'
import {SyntheticEvent} from 'react'
import * as styles from './styles'

const AccountDropDown = (props: {
  required: boolean
  label: string
  value: string | undefined
  placeholder: string
  values: Array<{text: string; value: string}>
  onChange: (event: SyntheticEvent, data: DropdownProps) => void
}): JSX.Element => {
  return (
    <div css={styles.wrapperCss}>
      <Dropdown
        css={props.required ? styles.requiredCss : styles.dropDownCss}
        options={props.values}
        placeholder={props.placeholder}
        value={props.value}
        clearable
        fluid
        search
        selection
        onChange={props.onChange}
      />
      <label css={props.required ? styles.requiredLabelCss : styles.labelCss}>
        {props.label}
      </label>
      <label
        css={
          props.required ? styles.requiredTextCss : styles.requiredTextHiddenCss
        }
      >
        Field is required.
      </label>
    </div>
  )
}

export default AccountDropDown
