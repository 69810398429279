import {css} from '@emotion/core'

export const textCss = css`
  float: left;
  width: 359px;
  height: 38px;
  top: 50%;
  font-size: 16px;
  color: #2a2a2a;
  font-family: Rubik;
`

export const dropdownCss = css`
  float: right;
  width: 315px;
  padding: ;
`

export const groupCss = css`
  height: 55px;
  width: 708px;
  margin: 20px 65px 0px 65px;
`

export const titleCss = css`
  height: 24px;
  width: 202px;
  color: #2a2a2a;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 34px;
  margin-left: 65px;
  text-align: left;
`
export const lineCss = css`
  height: 1px;
  width: 708px;
  background-color: #eaeaea;
`
export const finishCss = css`
  float: right;
  width: 175px !important;
`

export const backCss = css`
  float: left;
  width: 175px !important;
  background-color: white !important;
  color: #2a2a2a !important;
  border: 1px solid #929292 !important;
`

export const buttonGroupCss = css`
  position: relative;
  left: 50%;
  margin-left: -195px;
  margin-top: 17.5px;
  height: 41px;
  width: 390px;
`

export const buttonContainer = css`
  box-sizing: border-box;
  height: 74px;
  border-top: 1px solid #d7d7d7;
  background-color: #ffffff;
  margin-top: 39.5px;
`

export const cancelCss = css`
  float: left;
  height: 40px !important;
  width: 175px !important;
  text-align: right;
  color: #626262;
  font-family: Rubik;
  font-size: 16px;
  margin: 10px !important;
`
