/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {Redirect} from 'react-router'
import {useMutation} from '@apollo/react-hooks'
import {Image} from 'semantic-ui-react'
import ErrorPage from '../Error'
import {AccountingContext} from '@app/context/accountingContext'
import {Paths, IntegrationStatus} from '@app/models/constants'
import ConnectionDetailsComp from './components/ConnectionDetails'
import circleCheck from 'Assets/images/app/circle-check.svg'
import {SET_STATUS} from '@app/graphql/integration.graphql'
import {GraphIntegration} from '@app/models/integrationModel'
import Css from './css'
import {Structure} from '@mhd/components-library'
const {Loading} = Structure

const ConfirmConnection = (): JSX.Element => {
  const {activeIntegration, errorMessage, loading, text} = useContext(
    AccountingContext,
  )

  const redirect = (): void => {
    window.location.pathname = Paths.ChartOfAccounts
  }

  const [confirm] = useMutation<GraphIntegration>(SET_STATUS)

  const next = async (): Promise<void> => {
    await confirm({
      variables: {
        statusChangeParam: {
          integrationStatusId: IntegrationStatus.NeedChartOfAccounts,
        },
      },
    })
    redirect()
  }

  const content = (
    <div css={Css.ParentDiv}>
      <div css={Css.TopBlock}>
        <div css={Css.Header}>Connection Successful!</div>
        <div css={Css.Oval}>
          <Image css={Css.CircleCheck} src={circleCheck} />
        </div>
      </div>
      <div css={Css.MiddleBlock}>
        <div css={Css.Spacer}></div>
        <div css={Css.Text}>
          You have connected {text.IntegrationAppName} to your{' '}
          {text.ConnectorAppName} account!
        </div>
        <div css={Css.Text}>
          Click{' '}
          <a href={Paths.Setup} css={Css.Link}>
            Change Account
          </a>{' '}
          to select a different {text.ConnectorAppName} account or Next to
          proceed with setting up your chart of accounts.
        </div>
        <ConnectionDetailsComp showChangeAccountLink={true} />
      </div>
      <div css={Css.BottomBlock}>
        <button css={Css.Button} onClick={next}>
          <span css={Css.ButtonLabel}>Next</span>
        </button>
      </div>
      <div css={Css.Strip}>
        <div css={Css.LilStrip}></div>
      </div>
    </div>
  )

  return loading ? (
    <Loading />
  ) : errorMessage ? (
    <ErrorPage message={errorMessage} />
  ) : activeIntegration &&
    activeIntegration.integrationId &&
    activeIntegration.integrationStatusId === IntegrationStatus.NeedSetup ? (
    content
  ) : (
    <Redirect to={Paths.Home} />
  )
}

export default ConfirmConnection
