export class AccountSelection {
  public productIncome: string | undefined
  public productExpense: string | undefined
  public productAsset: string | undefined
  public serviceIncome: string | undefined
  public serviceExpense: string | undefined
  public accountsReceivable: string | undefined
}

export class SelectionRequired {
  public productIncomeRequired = false
  public productExpenseRequired = false
  public productAssetRequired = false
  public serviceIncomeRequired = false
  public serviceExpenseRequired = false
  public accountsReceivableRequired = false
}
