import gql from 'graphql-tag'

export const GET_ACCOUNTS = gql`
  query ChartOfAccounts($param: AccountParam) {
    getChartOfAccounts(param: $param) {
      id
      name
      accountType
      accountSubType
    }
  }
`
