/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext, useState} from 'react'
import {AccountingContext} from '@app/context/accountingContext'
import ErrorPage from '../Error'
import {IntegrationStatus, IntegrationType} from '@app/models/constants'
import {Redirect} from 'react-router-dom'
import AccountSettings from './Components/AccountSettings'
import {AccountSelection, SelectionRequired} from '@app/models/accountSelection'
import {Paths} from '@app/models/constants'
import dollarSign from 'Assets/images/app/dollar-sign.svg'
import {Image} from 'semantic-ui-react'
import * as styles from './styles'

const ChartOfAccounts = (): JSX.Element => {
  const {activeIntegration, text} = useContext(AccountingContext)

  const [error, setError] = useState('')
  const [accountSelection, setAccountSelection] = useState(
    activeIntegration
      ? {
          productIncome: activeIntegration.productIncomeAccountExtId,
          productExpense: activeIntegration.productExpenseAccountExtId,
          productAsset: activeIntegration.productAssetAccountExtId,
          serviceIncome: activeIntegration.serviceIncomeAccountExtId,
          serviceExpense: activeIntegration.serviceExpenseAccountExtId,
          accountsReceivable: activeIntegration.accountsReceivableAccountExtId,
        }
      : new AccountSelection(),
  )

  const [selectionRequired, setSelectionRequired] = useState(
    new SelectionRequired(),
  )

  const content = (
    <div css={styles.mainDivCss}>
      <div css={styles.topBlockCss}>
        <div css={styles.headerCss}>Chart of Accounts</div>
        <div css={styles.ovalCss}>
          <Image css={styles.circleCheckCss} src={dollarSign} />
        </div>
      </div>
      <div css={styles.instructionsCss}>
        To sync data with your {text.ConnectorAppName} account, you must select
        an account for each item and transaction type created in{' '}
        {text.IntegrationAppName}.
        <br /> <br /> For assistance in selecting the correct accounts, please
        consult with your accountant. If you have any other questions, call{' '}
        {text.IntegrationAppName} support at{' '}
        <span css={styles.blueTextCss}>{text.IntegrationAppPhone}</span>
        <span hidden={text.integrationType === IntegrationType.MHD}>
          {' '}
          or <span css={styles.blueTextCss}>businesscenter@angieslist.com</span>
        </span>
        .
      </div>
      <AccountSettings
        currentValues={accountSelection}
        selectionRequired={selectionRequired}
        setAccountSelection={setAccountSelection}
        setError={setError}
        setSelectionRequired={setSelectionRequired}
      />
      <div>
        <hr css={styles.greenlineCss} />
        <hr css={styles.graylineCss} />
      </div>
    </div>
  )

  return !activeIntegration ||
    (activeIntegration &&
      activeIntegration.integrationStatusId !==
        IntegrationStatus.NeedChartOfAccounts) ? (
    <Redirect to={Paths.Home} />
  ) : error ? (
    <ErrorPage message={error} />
  ) : (
    content
  )
}

export default ChartOfAccounts
