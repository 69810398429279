export class GraphIntegration {
  public getIntegration!: IntegrationData
  public setChartOfAccounts!: IntegrationData
}

export class IntegrationData {
  public integrationId: number | undefined
  public externalId: string | undefined
  public integrationTypeId: number | undefined
  public connectorTypeId: number | undefined
  public integrationStatusId: number | undefined
  public syncSessionStatusId: number | undefined
  public isActive: boolean | undefined
  public productIncomeAccountExtId: string | undefined
  public productExpenseAccountExtId: string | undefined
  public productAssetAccountExtId: string | undefined
  public serviceIncomeAccountExtId: string | undefined
  public serviceExpenseAccountExtId: string | undefined
  public accountsReceivableAccountExtId: string | undefined
  public createdBy: string | undefined
  public createdDate: string | undefined
  public updatedBy: string | undefined
  public updatedDate: string | undefined
  public currentUser!: CurrentUser
}

export class CurrentUser {
  public portalId: string | undefined
  public userId: string | undefined
  public name: string | undefined
}

export class GraphConnectionDetails {
  public getConnectionDetails!: ConnectionDetails
}

export class ConnectionDetails {
  public system: string | undefined
  public companyName: string | undefined
  public companyID: string | undefined
  public username: string | undefined
}
