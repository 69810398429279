/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

export interface ButtonProps {
  onClick: () => void
}

const Button = (
  props: ButtonProps & Semantic.StrictButtonProps,
): JSX.Element => {
  const {secondary, onClick, ...otherProps} = props

  const buttonCss = css`
    height: 40px !important;
    min-width: 45px !important;
    border-radius: 4px !important;
    background-color: #048540 !important;
    color: white !important;
    border: none !important;
    font-family: Rubik !important;
    font-weight: 500 !important;
    font-size: 16px !important;
  `
  const handleClick = (): void => {
    onClick()
  }

  return (
    <Semantic.Button
      css={buttonCss}
      basic={secondary}
      primary={!secondary}
      fluid
      onClick={handleClick}
      {...otherProps}
    />
  )
}

export default Button
