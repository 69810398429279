import React, {useEffect} from 'react'
import {useMutation} from '@apollo/react-hooks'
import queryString from 'query-string'
import {SAVE_TOKEN} from '@app/graphql/integration.graphql'
import {GraphIntegrationAuth} from '@app/models/integrationAuthModel'
import {Paths} from '@app/models/constants'

const QuickBooksCallback = (): JSX.Element => {
  const redirect = (path: string): void => {
    window.location.replace(window.location.origin + path)
  }

  const [saveToken] = useMutation<GraphIntegrationAuth>(SAVE_TOKEN)

  const handleCallback = async (): Promise<void> => {
    const values = queryString.parse(window.location.search)

    if (values && values.code && values.state === 'mHelpDeskConnectState') {
      //call bff to exchange for token and save to accounting api
      const authUri = window.location.href
      const realmId = values.realmId
      await saveToken({
        variables: {
          tokenParam: {
            externalId: realmId,
            authUri: authUri,
          },
        },
      })
      redirect(Paths.ConfirmConnection)
    } else {
      console.log(values)
      redirect(Paths.Home)
    }
  }

  useEffect((): void => {
    handleCallback()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <div />
}

export default QuickBooksCallback
