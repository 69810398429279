import {css} from '@emotion/core'

export const setupTextCss = css`
  left: 50%;
  margin-top: 73px;
  position: relative;
  font-family: Rubik;
  font-weight: 300;
  font-size: 18px;
  color: #626262;
  line-height: 2.5;
  text-align: center;
  margin-left: -354px;
  height: 66px;
  width: 708px;
`

export const parentDivCss = css`
  width: 839px;
  height: 420px;
  margin: 0 auto;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -420px;
  margin-top: -210px;
  border-width: 1px;
  border-color: #d3d3d3;
  border-style: solid;
`

export const buttonCss = css`
  position: absolute;
  left: 50%;
  margin-left: -58px;
  margin-top: 40px;
`

export const textCss = css`
  display: inline;
  color: #2c9f1b;
`

export const topBlockCss = css`
  position: relative;
  box-sizing: border-box;
  height: 130px;
  width: 837px;
  border-bottom: 1px solid #d6d6d6;
`

export const headerCss = css`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  color: #2a2a2a;
  font-family: Bitter;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
`

export const ovalCss = css`
  position: relative;
  top: 80px;
  left: 50%;
  transform: translate(-50%);
  height: 100px;
  width: 100px;
  background-color: #ffffff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  z-index: 10;
`

export const iconCss = css`
  height: 40px;
  width: 40px;
  background-color: #048540;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
`
