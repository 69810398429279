/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext, useState} from 'react'
import {ApolloError} from 'apollo-client'
import {useQuery} from '@apollo/react-hooks'
import {AccountingContext} from '@app/context/accountingContext'
import {GET_CONNECTION_DETAILS} from '@app/graphql/integration.graphql'
import Css from './css'
import {
  GraphConnectionDetails,
  ConnectionDetails,
} from '@app/models/integrationModel'
import {Paths} from '@app/models/constants'
import Loading from '@mhd/components-library/dist/components/Structure/Loading'

const ConnectionDetailsComp = (props: {
  showChangeAccountLink: boolean
}): JSX.Element => {
  const {setErrorMessage, text} = useContext(AccountingContext)

  const [connectionDetails, setConnectionDetails] = useState(
    new ConnectionDetails(),
  )

  const {loading} = useQuery<GraphConnectionDetails>(GET_CONNECTION_DETAILS, {
    onCompleted: (data): void => {
      if (data) {
        setConnectionDetails(data.getConnectionDetails)
      }
    },
    onError: (error: ApolloError): void => {
      if (error.message.includes('401')) {
        setErrorMessage(text.UnauthorizedTryRefresh())
      } else {
        setErrorMessage(error.message)
      }
    },
  })

  return loading ? (
    <Loading />
  ) : (
    <div css={Css.ParentDiv}>
      <div css={Css.LeftBlock}>
        <div css={Css.HeaderItem}>System</div>
        <div css={Css.HeaderItem}>Company Name</div>
        <div css={Css.HeaderItem}>Company ID</div>
        <div css={Css.HeaderItem}>Username</div>
      </div>
      <div css={Css.RightBlock}>
        <div css={Css.ValueItem}>{connectionDetails.system}</div>
        <div css={Css.ValueItem}>{connectionDetails.companyName}</div>
        <div css={Css.ValueItem}>{connectionDetails.companyID}</div>
        <div css={Css.ValueItem}>
          {connectionDetails.username}
          {props.showChangeAccountLink && (
            <span>
              &nbsp;(
              <a href={Paths.Setup} css={Css.Link}>
                Change Account
              </a>
              )
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ConnectionDetailsComp
