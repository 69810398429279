import {IntegrationType, ConnectorType} from '@app/models/constants'

const integrationAppName = (integrationType: number): string => {
  switch (integrationType) {
    case IntegrationType.MHD:
      return 'mHelpDesk'
    case IntegrationType.BMT:
      return "Angie's List"
    default:
      return 'the parent site'
  }
}

const connectorAppName = (connectorType: number): string => {
  switch (connectorType) {
    case ConnectorType.QBO:
      return 'QuickBooks Online'
    default:
      return 'the accounting system'
  }
}

const integrationAppPhone = (integrationType: number): string => {
  switch (integrationType) {
    case IntegrationType.MHD:
      return '888-558-6275'
    case IntegrationType.BMT:
      return '866-843-5478'
    default:
      return '888-558-6275'
  }
}

const integrationAppEmail = (integrationType: number): string => {
  switch (integrationType) {
    case IntegrationType.BMT:
      return 'businesscenter@angieslist.com'
    default:
      return ''
  }
}

export class Text {
  integrationType: number
  connectorType: number
  public IntegrationAppName: string
  public ConnectorAppName: string
  public IntegrationAppPhone: string
  public IntegrationAppEmail: string
  constructor(integrationType: number, connectorType: number) {
    this.integrationType = integrationType
    this.connectorType = connectorType
    this.IntegrationAppName = integrationAppName(integrationType)
    this.ConnectorAppName = connectorAppName(connectorType)
    this.IntegrationAppPhone = integrationAppPhone(integrationType)
    this.IntegrationAppEmail = integrationAppEmail(integrationType)
  }
  public UnauthorizedTryRefresh = (): string =>
    `Unauthorized, you have to be signed into ${this.IntegrationAppName}. If you have already signed into ${this.IntegrationAppName}, try refreshing ${this.IntegrationAppName} and reopening this page from there.`
}
