import {css} from '@emotion/core'
import ParentCss from '../../css'

const itemCss = `
margin-top: 20px;
color: #2a2a2a;
font-family: Rubik;
font-size: 16px;
letter-spacing: 0;
line-height: 19px;
`

const space = '20px'

export default class Css {
  public static ParentDiv = css`
    display: block;
  `

  public static LeftBlock = css`
    display: inline-block;
    width: 50%;
    font-weight: 500;
    text-align: right;
  `

  public static RightBlock = css`
    display: inline-block;
    width: 50%;
  `

  public static HeaderItem = css`
    margin-right: ${space};
    ${itemCss}
  `

  public static ValueItem = css`
    margin-left: ${space};
    ${itemCss}
  `

  public static Link = ParentCss.Link
}
