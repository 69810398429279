import {css} from '@emotion/core'

const parentHeight = 605
const parentWidth = 840
const stripHeight = 6
const lilStripWidth = parentWidth * 0.4

const headerFont = `
color: #2a2a2a;
font-family: Bitter;
font-size: 24px;
font-weight: bold;
line-height: normal;
letter-spacing: normal;
`

const centerParent = `
position: relative;
`

const centerChild = `
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`

const horizontalCenter = `
left: 50%;
transform: translate(-50%);
`

const circleBorder = `
box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
border: 1px solid #d7d7d7;
border-radius: 50%;
-moz-border-radius: 50%;
-webkit-border-radius: 50%;
`

export default class Css {
  public static ParentDiv = css`
    width: ${parentWidth}px;
    height: ${parentHeight}px;
    background-color: transparent;
    border: 1px solid #d6d6d6;
    z-index: 2;
    ${centerChild}
  `
  public static Header = css`
    position: absolute;
    top: 30px;
    ${horizontalCenter}
    ${headerFont}
  `
  public static Text = css`
    display: block;
    color: #626262;
    font-family: Rubik;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    margin: 20px;
  `
  public static Button = css`
    height: 40px;
    width: 175px;
    border-radius: 4px;
    background-color: #048540;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
    border: none;
    ${centerChild}
  `

  public static ButtonLabel = css`
    height: 30px;
    width: 37px;
    color: #ffffff;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
  `

  public static TopBlock = css`
    z-index: 1;
    box-sizing: border-box;
    height: 130px;
    width: ${parentWidth}px;
    border-bottom: 1px solid #d6d6d6;
    background-color: #ffffff;
  `

  public static MiddleBlock = css`
    z-index: 1;
    box-sizing: border-box;
    height: 400px;
    width: ${parentWidth}px;
    border-bottom: 1px solid #d6d6d6;
    background-color: #ffffff;
    ${centerParent}
  `

  public static BottomBlock = css`
    z-index: 1;
    box-sizing: border-box;
    height: 75px;
    width: ${parentWidth}px;
    background-color: #ffffff;
    ${centerParent}
  `

  public static Strip = css`
    z-index: 1;
    height: ${stripHeight}px;
    width: ${parentWidth}px;
    background-color: #eaeaea;
  `

  public static LilStrip = css`
    z-index: 1;
    height: ${stripHeight}px;
    width: ${lilStripWidth}px;
    background-color: #048540;
  `

  public static Oval = css`
    top: 80px;
    height: 100px;
    width: 100px;
    background-color: #ffffff;
    z-index: 10;
    ${circleBorder}
    ${centerParent}
    ${horizontalCenter}
  `

  public static CircleCheck = css`
    height: 40px;
    width: 40px;
    background-color: #048540;
    text-align: center;
    z-index: 20;
    ${centerChild}
  `

  public static Spacer = css`
    height: 65px;
  `

  public static Link = css`
    color: #007db8;
    cursor: pointer;
  `
}
