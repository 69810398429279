/** @jsx jsx */
import {jsx, css} from '@emotion/core'

import {Menu, Image} from 'semantic-ui-react'
import angiesListLogo from 'Assets/images/app/AngiesListLogo.svg'
import mhdLogo from 'Assets/images/app/MHD Logo.png'
import {IntegrationType} from '@app/models/constants'

const TopBar = (props: {
  integrationTypeId: number | undefined
}): JSX.Element => {
  const parentDivCss = css`
    position: absolute;
    left: 15%;
    top: 16px;
    height: 54px;
    width: 500px;
  `

  const angiesListLogoCss = css`
    height: 44px;
    width: 175px;
    top: -6px;
    left: 9px;
  `

  const mhdLogoCss = css`
    height: 36px;
    float: right;
  `

  const amTitleCss = css`
    height: 22px;
    width: 261px;
    color: #048540;
    font-family: Rubik;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    display: block;
    float: left;
    position: absolute;
    left: 186px;
    top: 8px;
  `

  const imageDivCss = css`
    height: 70px;
    width: 175px;
  `

  const menuCss = css`
    height: 70px;
  `

  const logoImage =
    props.integrationTypeId === IntegrationType.MHD ? (
      <Image css={mhdLogoCss} src={mhdLogo} />
    ) : props.integrationTypeId === IntegrationType.BMT ? (
      <Image css={angiesListLogoCss} src={angiesListLogo} />
    ) : (
      <div />
    )

  return (
    <div>
      <Menu attached="top" css={menuCss} size="large">
        <div css={parentDivCss}>
          <div css={imageDivCss}>{logoImage}</div>
          <div css={amTitleCss}> Accounting System Integration</div>
        </div>
      </Menu>
    </div>
  )
}

export default TopBar
