import gql from 'graphql-tag'

export const START_SYNC = gql`
  mutation startSync($integrationId: Int!, $portalId: String!) {
    startSync(integrationId: $integrationId, portalId: $portalId) {
      success
      errorMessage
    }
  }
`

export const RESET_SYNC = gql`
  mutation resetSync($integrationId: Int!, $portalId: String!) {
    resetSync(integrationId: $integrationId, portalId: $portalId) {
      success
      errorMessage
    }
  }
`
