/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {Redirect} from 'react-router'
import {useMutation} from '@apollo/react-hooks'
import {Paths} from '@app/models/constants'
import {Semantic, Structure} from '@mhd/components-library'
import Button from '@app/components/Button'
import * as styles from './styles'
import ErrorPage from '../Error'
import alQbConnection from '@app/assets/images/app/alQbConnection.svg'
import arrowLeft from '@app/assets/images/app/arrowLeft.svg'
import ConnectionDetailsComp from '../ConfirmConnection/components/ConnectionDetails'
import {AccountingContext} from '@app/context/accountingContext'
import {SET_STATUS} from '@app/graphql/integration.graphql'
import {GraphIntegration} from '@app/models/integrationModel'

const {Loading} = Structure

const AccountConnection = (): JSX.Element => {
  const {activeIntegration, errorMessage, loading, text} = useContext(
    AccountingContext,
  )

  const redirect = (): void => {
    window.location.pathname = Paths.Home
  }

  const [deactivating] = useMutation<GraphIntegration>(SET_STATUS)
  const changeAccount = async (): Promise<void> => {
    await deactivating({
      variables: {
        statusChangeParam: {
          isActive: false,
        },
      },
    })
    redirect()
  }
  const content = (
    <div>
      <div css={{marginLeft: 200, marginTop: 30}}>
        <img alt="arrowLeft" src={arrowLeft} css={styles.arrowLeft} />
        <a href={Paths.Preferences} css={{fontFamily: 'Rubik'}}>
          Back to Preferences
        </a>
      </div>
      <Semantic.Header css={styles.headerCss}>
        Accounting System Connection
      </Semantic.Header>
      <div css={styles.mainDivCss}>
        <div css={styles.paddingCss}>
          <div>
            <img
              alt="alQbConnection"
              src={alQbConnection}
              css={styles.alQbConnImgCss}
            />
          </div>
          <div css={styles.connText}>
            Your {text.IntegrationAppName} account is configured to sync data
            with {text.ConnectorAppName}.
          </div>
          <div css={styles.connText}>
            Click{' '}
            <span
              onClick={changeAccount}
              css={{color: '#048540', cursor: 'pointer'}}
            >
              Change Account
            </span>{' '}
            if you would like to swith to an alternate user acccount; otherwise,
            click
            <a href={Paths.Preferences}> Cancel</a> to return to the Preferences
            menu.
          </div>
          <ConnectionDetailsComp showChangeAccountLink={false} />
        </div>

        <div css={styles.BottomBlock}>
          <div css={styles.cancelBtn}>
            <a href={Paths.Preferences}>Cancel</a>
          </div>
          <Button css={styles.changeAccountBtn} onClick={changeAccount}>
            Change Account
          </Button>
        </div>
      </div>
    </div>
  )
  return loading ? (
    <Loading />
  ) : errorMessage ? (
    <ErrorPage message={errorMessage} />
  ) : activeIntegration && activeIntegration.integrationId ? (
    content
  ) : (
    <Redirect to={Paths.Home} />
  )
}
export default AccountConnection
