/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {useMutation} from '@apollo/react-hooks'
import * as styles from '../../styles'
import {AccountingContext} from '@app/context/accountingContext'
import {
  SyncSessionStatus,
  IntegrationStatus,
  Paths,
} from '@app/models/constants'
import {Semantic} from '@mhd/components-library'
import {SET_STATUS} from '@app/graphql/integration.graphql'
import {GraphIntegration} from '@app/models/integrationModel'
import Button from '@app/components/Button'

export interface SyncProgressProps {
  onClick?: () => void
}
const SyncProgress = (props: SyncProgressProps): JSX.Element => {
  const {activeIntegration} = useContext(AccountingContext)
  const [setReadyToSync] = useMutation<GraphIntegration>(SET_STATUS)
  const returnReadyToSync = async (): Promise<void> => {
    await setReadyToSync({
      variables: {
        statusChangeParam: {
          integrationStatusId: IntegrationStatus.ReadyToSync,
        },
      },
    })
    window.location.pathname = Paths.Home
  }

  let syncStatus = ''
  let component = {}
  if (activeIntegration) {
    switch (activeIntegration.integrationStatusId) {
      case IntegrationStatus.SyncInProgress:
        syncStatus = 'Sync in Progress'
        component = (
          <Semantic.Header as="h3" css={styles.textCss}>
            Importing and Exporting Data…
          </Semantic.Header>
        )
        break
      case IntegrationStatus.ReadyToSync:
        syncStatus = 'Ready to Sync'
        component = (
          <Semantic.Header as="h3" css={styles.textCss}>
            Click <span css={{color: '#048540'}}>Start Sync</span> to begin your
            sync.
          </Semantic.Header>
        )
        break
      case IntegrationStatus.SyncCompleted:
        syncStatus = 'Sync Complete'
        component = (
          <Semantic.Header as="h3" css={styles.textCss}>
            Congratulations! Your sync is complete. Click{' '}
            <span css={styles.conflictTxtCss}>Conflicts</span> to review the
            conflicts found during your sync, or click{' '}
            <span css={styles.returnTxtCss}>Return</span> to sync again.
          </Semantic.Header>
        )
        break
      case IntegrationStatus.Error:
        syncStatus = 'Error Encountered In Last Sync'
        component = (
          <Semantic.Header as="h3" css={styles.textCss}>
            Click <span css={{color: '#048540'}}>Start Sync</span> to begin your
            sync.
          </Semantic.Header>
        )
        break
    }
  }
  let progressPercent = 0

  if (activeIntegration && activeIntegration.syncSessionStatusId) {
    switch (activeIntegration.syncSessionStatusId) {
      case SyncSessionStatus.Ready:
        progressPercent = 0
        break
      case SyncSessionStatus.ConnectorImportCompleted:
        progressPercent = 25
        break
      case SyncSessionStatus.MhdImportCompleted:
        progressPercent = 50
        break
      case SyncSessionStatus.MhdExportCompleted:
        progressPercent = 75
        break
      case SyncSessionStatus.ConnectorExportCompleted:
        progressPercent = 100
        break
      case SyncSessionStatus.Completed:
        progressPercent = 100
        break
      default:
        progressPercent = 0
    }
  }
  const syncComplete =
    activeIntegration &&
    activeIntegration.integrationStatusId === IntegrationStatus.SyncCompleted

  return (
    <div css={styles.progressDiv}>
      <div css={styles.progressGridDiv}>
        <div css={styles.syncRowGridLeft}>
          <Semantic.Header css={styles.headerCss}>{syncStatus}</Semantic.Header>
          {component}
        </div>
        <div css={styles.syncRowGridRight}>
          {activeIntegration &&
          (activeIntegration.integrationStatusId ===
            IntegrationStatus.ReadyToSync ||
            activeIntegration.integrationStatusId ===
              IntegrationStatus.Error) ? (
            <Semantic.Button css={styles.syncButtonCss} onClick={props.onClick}>
              Start Sync
            </Semantic.Button>
          ) : syncComplete ? (
            <div>
              <Button
                css={styles.returnBtnCss}
                onClick={(): Promise<void> => returnReadyToSync()}
              >
                Return
              </Button>
              <Semantic.Button
                css={styles.syncButtonCss}
                onClick={(): void => {
                  window.location.pathname = Paths.Conflicts
                }}
              >
                Conflicts
              </Semantic.Button>
            </div>
          ) : (
            undefined
          )}
        </div>
      </div>
      <Semantic.Progress
        percent={
          activeIntegration &&
          activeIntegration.integrationStatusId !==
            IntegrationStatus.SyncCompleted
            ? progressPercent
            : 100
        }
        size="large"
        css={[
          styles.progressCss,
          progressPercent !== 0 || syncComplete ? styles.progressBarCss : null,
        ]}
        progress={progressPercent !== 0 || syncComplete}
      />
    </div>
  )
}

export default SyncProgress
