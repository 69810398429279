import {css} from '@emotion/core'

export const wrapperCss = css`
  width: 315px;
`

export const dropDownCss = css`
  border: 1px solid #929292 !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
`

export const requiredCss = css`
  border: 3px solid #db0000 !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
`

export const labelCss = css`
  position: relative;
  top: -3.5em;
  left: 1em;
  font-family: Rubik;
  font-size: 1em;
  padding: 0 5px;
  color: #2a2a2a;
  background-color: white;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 20px;
`

export const requiredLabelCss = css`
  position: relative;
  top: -3.7em;
  left: 1em;
  font-family: Rubik;
  font-size: 1em;
  padding: 0 5px;
  color: #db0000;
  background-color: white;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 20px;
`

export const requiredTextCss = css`
  display: block;
  height: 18px;
  width: 108px;
  color: #db0000;
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  top: -1.5em;
  position: relative;
`

export const requiredTextHiddenCss = css`
  display: none;
  height: 18px;
  width: 108px;
  color: #db0000;
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  top: -1.5em;
  position: relative;
`
