/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useEffect, useState} from 'react'
import {Route, BrowserRouter} from 'react-router-dom'
import {Structure} from '@mhd/components-library'
import {useLazyQuery} from '@apollo/react-hooks'
import Home from '@app/screens/Home'
import Setup from '@app/screens/Setup'
import ConfirmConnection from '@app/screens/ConfirmConnection'
import useUiSettings from '@app/helpers/customHooks/uiSettings'
import {Text} from '@app/helpers/textStrings'
import {
  GraphIntegration,
  IntegrationData,
  CurrentUser,
} from '@app/models/integrationModel'
import {GET_INTEGRATION} from '@app/graphql/integration.graphql'

import ErrorPage from '../Error'
import QuickBooksCallback from '../Setup/components/quickBooksCallback/index'
import {ApolloError} from 'apollo-client'
import Conflicts from '@app/screens/Conflicts'
import Logs from '@app/screens/Logs'
import Preferences from '@app/screens/Preferences'
import MainNavigation from './components/mainNavigation/index'
import {AccountingContext} from '@app/context/accountingContext'
import WebFont from 'webfontloader'
import ChartOfAccounts from '../ChartOfAccounts'
import AccountConnection from '../Preferences/accountConnection'
import EditChartOfAccounts from '../Preferences/EditChartOfAccounts'
import {Container} from 'semantic-ui-react'
import {ConnectorType, Paths} from '@app/models/constants'
import * as styles from './styles'

const {Loading} = Structure

const App = (): JSX.Element => {
  WebFont.load({
    google: {
      families: ['Rubik:300,400,500', 'Nunito Sans', 'Bitter:700'],
    },
  })
  const uiSettings = useUiSettings()
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [activeIntegration, setActiveIntegration] = useState(
    new IntegrationData(),
  )
  const [currentUser, setCurrentUser] = useState(new CurrentUser())
  const [text, setText] = useState(new Text(0, 0))

  const [showBackground, setShowBackground] = useState(false)

  const [getIntegration] = useLazyQuery<GraphIntegration>(GET_INTEGRATION, {
    onCompleted: (data): void => {
      setLoading(false)
      if (data) {
        setActiveIntegration(data.getIntegration)
        setCurrentUser(data.getIntegration.currentUser)
      }
    },
    onError: (error: ApolloError): void => {
      setLoading(false)
      if (error.message.includes('401')) {
        setErrorMessage(text.UnauthorizedTryRefresh())
      } else {
        setErrorMessage(error.message)
      }
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect((): void => {
    uiSettings.get().then(result => {
      if (result.next) {
        setText(new Text(result.integrationType, ConnectorType.QBO))
        getIntegration()
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const appContent = (
    <div>
      <AccountingContext.Provider
        value={{
          currentUser,
          loading,
          errorMessage,
          activeIntegration,
          settings: uiSettings.settings,
          setActiveIntegration,
          setCurrentUser,
          setLoading,
          setErrorMessage,
          text,
          setShowBackground,
        }}
      >
        <MainNavigation>
          <Container
            css={
              showBackground
                ? styles.containerBackgroundCss
                : styles.containerCss
            }
            fluid
          >
            <BrowserRouter>
              <Route component={Home} path={Paths.Home} exact />
              <Route component={Setup} path={Paths.Setup} exact />
              <Route
                component={ConfirmConnection}
                path={Paths.ConfirmConnection}
                exact
              />
              <Route
                component={QuickBooksCallback}
                path={Paths.QuickBooksCallback}
                exact
              />
              <Route component={Conflicts} path={Paths.Conflicts} exact />
              <Route component={Logs} path={Paths.Logs} exact />
              <Route component={Preferences} path={Paths.Preferences} exact />
              <Route
                component={ChartOfAccounts}
                path={Paths.ChartOfAccounts}
                exact
              />
              <Route
                component={AccountConnection}
                path={Paths.AccountConnection}
                exact
              />
              <Route
                component={EditChartOfAccounts}
                path={Paths.EditChartOfAccounts}
                exact
              />
            </BrowserRouter>
          </Container>
        </MainNavigation>
      </AccountingContext.Provider>
    </div>
  )

  return loading || uiSettings.loading ? (
    <Loading />
  ) : uiSettings.errorMessage ? (
    <ErrorPage message={uiSettings.errorMessage} />
  ) : errorMessage ? (
    <ErrorPage message={errorMessage} />
  ) : (
    appContent
  )
}

export default App
