import {css} from '@emotion/core'

const header = css`
  font-family: Rubik !important;
  font-weight: 500 !important;
  color: #2a2a2a !important;
`

export const mainDivCss = css({
  position: 'relative',
  marginLeft: '14.0em',
  width: '52%',
  border: '1px solid #d6d6d6',
  backgroundColor: '#ffffff',
  marginBottom: '3.0em',
  marginTop: '2.0em',
})

export const headerCss = css`
  font-size: 24px !important;
  ${header}
`
export const subheaderCss = css`
  font-size: 20px !important;
  margin-left: 200px !important;
  ${header}
`
export const textCss = css`
  font-size: 16px !important;
  font-family: Rubik !important;
  color: #2a2a2a !important;
  margin-right: 20px !important;
`

export const alQbConnImgCss = css({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const gridDiv = css({
  display: 'grid',
  gridTemplateColumns: '40% 60%',
  padding: '30px',
})

export const rowGrid = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const rowGridLeft = css({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  borderRight: '1px solid #d6d6d6',
})

export const syncRowGridLeft = css({
  gridColumnStart: 1,
  gridColumnEnd: 2,
})

export const rowGridRight = css({
  gridColumnStart: 2,
  gridColumnEnd: 3,
})

export const syncRowGridRight = css({
  gridColumnStart: 2,
  gridColumnEnd: 3,
  position: 'relative',
})

export const progressCss = css({
  marginTop: '21px !important',
  marginBottom: '51px !important',
  borderRadius: '30px !important',
  height: '51px !important',
})
export const progressBarCss = css({
  '& .bar': {
    backgroundColor: '#048540 !important',
    borderRadius: '30px !important',
    height: '51px !important',
  },
  '& .progress': {
    color: 'white !important',
  },
})
export const progressGridDiv = css({
  display: 'grid',
  gridTemplateColumns: '70% 30%',
})

export const progressDiv = css({
  marginLeft: '200px',
  marginTop: '50px',
  width: '52%',
})

export const syncButtonCss = css`
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  min-width: 100px !important;
  height: 40px !important;
  border-radius: 4px !important;
  background-color: #048540 !important;
  color: white !important;
  border: none !important;
  font-family: Rubik !important;
  font-weight: 500 !important;
  font-size: 16px !important;
`
export const returnBtnCss = css`
  position: absolute !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100px !important;
  border: 1px solid #929292 !important;
  background-color: white !important;
  color: #2a2a2a !important;
`
export const conflictTxtCss = css`
  font-family: Rubik !important;
  font-weight: 500 !important;
  color: #048540 !important;
`
export const returnTxtCss = css`
  font-family: Rubik !important;
  font-weight: 500 !important;
`
