/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Menu, Sidebar} from 'semantic-ui-react'
import TopBar from '../topBar'
import {useContext} from 'react'
import {AccountingContext} from '@app/context/accountingContext'
import {IntegrationStatus, Paths} from '@app/models/constants'
import MenuItem from '../menuItem'
import home from '@app/assets/images/app/Home.svg'
import homeSelected from '@app/assets/images/app/HomeSelected.svg'
import homeGrey from '@app/assets/images/app/HomeGrey.svg'
import conflicts from '@app/assets/images/app/Conflicts.svg'
import conflictsSelected from '@app/assets/images/app/ConflictsSelected.svg'
import conflictsGrey from '@app/assets/images/app/ConflictsGrey.svg'
import preferences from '@app/assets/images/app/Preferences.svg'
import preSelected from '@app/assets/images/app/PreSelected.svg'
import preGrey from '@app/assets/images/app/PreGrey.svg'
import * as styles from '../../styles'

const MainNavigation = (props: {children: JSX.Element}): JSX.Element => {
  const {activeIntegration, text, setShowBackground} = useContext(
    AccountingContext,
  )

  const hideSideNav =
    !activeIntegration ||
    (activeIntegration && !activeIntegration.integrationId) ||
    (activeIntegration &&
      activeIntegration.integrationStatusId ===
        IntegrationStatus.NeedChartOfAccounts) ||
    (activeIntegration &&
      activeIntegration.integrationStatusId === IntegrationStatus.NeedSetup)

  setShowBackground(hideSideNav)

  return (
    <div>
      <TopBar integrationTypeId={text.integrationType} />
      <Sidebar.Pushable as="div" css={styles.pushableCss}>
        <Sidebar
          animation="push"
          as={Menu}
          css={{width: '220px !important'}}
          visible={!hideSideNav}
          vertical
        >
          <MenuItem
            redirectPath={Paths.Home}
            pathGroup={[Paths.Home]}
            menuItemName="Home"
            defaultImage={home}
            selectedImage={homeSelected}
            disabledImage={homeGrey}
            firstMenuItem={true}
          />
          <MenuItem
            redirectPath={Paths.Conflicts}
            pathGroup={[Paths.Conflicts]}
            menuItemName="Conflicts"
            defaultImage={conflicts}
            selectedImage={conflictsSelected}
            disabledImage={conflictsGrey}
          />
          <MenuItem
            redirectPath={Paths.Preferences}
            pathGroup={[
              Paths.Preferences,
              Paths.AccountConnection,
              Paths.EditChartOfAccounts,
            ]}
            menuItemName="Preferences"
            defaultImage={preferences}
            selectedImage={preSelected}
            disabledImage={preGrey}
          />
        </Sidebar>

        <Sidebar.Pusher css={styles.pusherCss}>{props.children}</Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  )
}

export default MainNavigation
