import gql from 'graphql-tag'

export const GET_INTEGRATION = gql`
  query Integration {
    getIntegration {
      integrationId
      externalId
      integrationTypeId
      connectorTypeId
      integrationStatusId
      syncSessionStatusId
      isActive
      productIncomeAccountExtId
      productExpenseAccountExtId
      productAssetAccountExtId
      serviceIncomeAccountExtId
      serviceExpenseAccountExtId
      accountsReceivableAccountExtId
      currentUser {
        portalId
        userId
        name
      }
    }
  }
`

export const GET_CONNECTION_DETAILS = gql`
  query Integration($user: UserInfo) {
    getConnectionDetails(user: $user) {
      system
      companyName
      companyID
      username
    }
  }
`

export const SAVE_TOKEN = gql`
  mutation Integration($tokenParam: TokenParam!) {
    saveToken(tokenParam: $tokenParam) {
      integrationId
      currentUser {
        portalId
        userId
        name
      }
    }
  }
`

export const SAVE_CHART_OF_ACCOUNTS = gql`
  mutation Integration($chartOfAccounts: ChartOfAccounts!) {
    setChartOfAccounts(chartOfAccounts: $chartOfAccounts) {
      integrationId
      externalId
      integrationTypeId
      connectorTypeId
      integrationStatusId
      syncSessionStatusId
      isActive
    }
  }
`

export const SET_STATUS = gql`
  mutation Integration($statusChangeParam: StatusChangeParam!) {
    setStatus(statusChangeParam: $statusChangeParam) {
      integrationStatusId
      isActive
    }
  }
`
