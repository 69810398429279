/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext, useState} from 'react'
import {Redirect} from 'react-router'
import {Paths, IntegrationType} from '@app/models/constants'
import {Semantic, Structure} from '@mhd/components-library'
import AccountSettings from '../ChartOfAccounts/Components/AccountSettings'
import {AccountingContext} from '@app/context/accountingContext'
import {AccountSelection, SelectionRequired} from '@app/models/accountSelection'
import arrowLeft from '@app/assets/images/app/arrowLeft.svg'
import * as styles from './styles'
import ErrorPage from '../Error'

const {Loading} = Structure

const AccountConnection = (): JSX.Element => {
  const {activeIntegration, loading, text} = useContext(AccountingContext)

  const [error, setError] = useState('')

  const [accountSelection, setAccountSelection] = useState(
    activeIntegration
      ? {
          productIncome: activeIntegration.productIncomeAccountExtId,
          productExpense: activeIntegration.productExpenseAccountExtId,
          productAsset: activeIntegration.productAssetAccountExtId,
          serviceIncome: activeIntegration.serviceIncomeAccountExtId,
          serviceExpense: activeIntegration.serviceExpenseAccountExtId,
          accountsReceivable: activeIntegration.accountsReceivableAccountExtId,
        }
      : new AccountSelection(),
  )

  const [selectionRequired, setSelectionRequired] = useState(
    new SelectionRequired(),
  )

  const content = (
    <div>
      <div css={{marginLeft: 200, marginTop: 30}}>
        <img alt="arrowLeft" src={arrowLeft} css={styles.arrowLeft} />
        <a href={Paths.Preferences} css={{fontFamily: 'Rubik'}}>
          Back to Preferences
        </a>
      </div>
      <Semantic.Header css={styles.headerCss}>
        Chart of Accounts
      </Semantic.Header>
      <div css={styles.mainDivCss}>
        <div css={styles.instructionsCss}>
          For assistance in selecting the correct accounts, please consult with
          your accountant. If you have any other questions, call{' '}
          {text.IntegrationAppName} support at{' '}
          <a href={`tel:${text.IntegrationAppPhone}`}>
            {text.IntegrationAppPhone}
          </a>{' '}
          <span hidden={text.integrationType === IntegrationType.MHD}>
            or{' '}
            <a
              href={`mailto:${text.IntegrationAppEmail}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {text.IntegrationAppEmail}
            </a>
          </span>
          .
        </div>
        <AccountSettings
          currentValues={accountSelection}
          selectionRequired={selectionRequired}
          setAccountSelection={setAccountSelection}
          setError={setError}
          setSelectionRequired={setSelectionRequired}
          type="edit"
        />
      </div>
    </div>
  )
  return loading ? (
    <Loading />
  ) : error ? (
    <ErrorPage message={error} />
  ) : activeIntegration && activeIntegration.integrationId ? (
    content
  ) : (
    <Redirect to={Paths.Home} />
  )
}
export default AccountConnection
