/** @jsx jsx */
import {jsx, css} from '@emotion/core'

import quickbooksLoginImage from 'Assets/images/quickBooks/C2QB_green_btn_med_default.png'
import quickbooksLoginImageHover from 'Assets/images/quickBooks/C2QB_green_btn_med_hover.png'
import {useState} from 'react'

const QuickBooksLogin = (props: {
  redirectToQuickbooks: Function
}): JSX.Element => {
  const buttonCss = css`
    width: 117px;
    height: 39px;
    float: right;
    &:hover {
      cursor: pointer;
    }
  `
  const [mouseOverButton, changeMouseOverButton] = useState(false)

  const hoverChange = (): void => {
    changeMouseOverButton(!mouseOverButton)
  }

  return (
    <div>
      <img
        alt="Connect To Quickbooks"
        css={buttonCss}
        src={mouseOverButton ? quickbooksLoginImageHover : quickbooksLoginImage}
        onClick={event => props.redirectToQuickbooks()}
        onMouseEnter={hoverChange}
        onMouseLeave={hoverChange}
      />
    </div>
  )
}

export default QuickBooksLogin
