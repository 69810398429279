/** @jsx jsx */
import {jsx} from '@emotion/core'

import {useContext} from 'react'
import {Semantic} from '@mhd/components-library'
import {AccountingContext} from '@app/context/accountingContext'

const {Header} = Semantic

const Logs = (): JSX.Element => {
  const {currentUser} = useContext(AccountingContext)

  return (
    <div>
      <Header as="h1">
        Logs Placeholder, Portal ID: {currentUser && currentUser.portalId}
      </Header>
    </div>
  )
}

export default Logs
