/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {Redirect} from 'react-router'
import {Semantic, Structure} from '@mhd/components-library'
import * as styles from './styles'
import ErrorPage from '../Error'
import {Paths} from '@app/models/constants'

import PreferencesEditItem from './components/PreferencesEditItem'
import {AccountingContext} from '@app/context/accountingContext'

const {Loading} = Structure

const Preferences = (): JSX.Element => {
  const {activeIntegration, errorMessage, loading} = useContext(
    AccountingContext,
  )
  const content = (
    <div>
      <Semantic.Header css={styles.headerCss}>Preferences</Semantic.Header>
      <PreferencesEditItem
        title="Accounting System Connection"
        content="Change the account used to sync data with your accounting system."
        type="connection"
      />
      <PreferencesEditItem
        title="Chart of Accounts"
        content="Specify which type of accounting activities to map your Chart of Accounts in your accounting system."
        type="chart"
      />
    </div>
  )
  return loading ? (
    <Loading />
  ) : errorMessage ? (
    <ErrorPage message={errorMessage} />
  ) : activeIntegration && activeIntegration.integrationId ? (
    content
  ) : (
    <Redirect to={Paths.Home} />
  )
}

export default Preferences
