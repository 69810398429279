import {IntegrationData, CurrentUser} from '@app/models/integrationModel'
import {Settings} from '@app/models/settings'
import {Text} from '@app/helpers/textStrings'
import {createContext} from 'react'

export interface AccountingContextInterface {
  currentUser: CurrentUser
  loading: boolean
  activeIntegration?: IntegrationData
  settings?: Settings
  text: Text
  setActiveIntegration: Function
  setCurrentUser: Function
  setLoading: Function
  errorMessage: string
  setErrorMessage: Function
  setShowBackground: Function
}

export const AccountingContext = createContext<AccountingContextInterface>({
  currentUser: new CurrentUser(),
  loading: true,
  errorMessage: '',
  setActiveIntegration: () => {},
  setCurrentUser: () => {},
  setLoading: () => {},
  setErrorMessage: () => {},
  text: new Text(0, 0),
  setShowBackground: () => {},
})
