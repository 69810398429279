import {css} from '@emotion/core'

const text = `
display: block;
color: #626262;
font-family: Rubik;
font-size: 18px;
letter-spacing: 0;
line-height: 22px;
margin: 20px;
`

export const mainDivCss = css({
  position: 'relative',
  marginLeft: '14.0em',
  width: '52%',
  border: '1px solid #d6d6d6',
  backgroundColor: '#ffffff',
  marginBottom: '3.0em',
  marginTop: '3.0em',
})

export const paddingCss = css({
  padding: '35.5px',
})

export const rowGrid = css({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  display: 'grid',
  gridTemplateColumns: '25% 25% 25% 25%',
  marginTop: '2.0em',
})

export const textCss = css({
  gridColumnStart: 1,
  gridColumnEnd: 4,
  fontFamily: 'Rubik !important',
  fontsize: '16px',
  color: '#2a2a2a !important',
})

export const buttonCss = css({
  marginTop: '0 !important',
  width: '85px !important',
  float: 'right',
})

export const buttonDiv = css({
  gridColumnStart: 4,
  gridColumnEnd: 5,
})

export const alQbConnImgCss = css({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const titleCss = css`
  font-family: Rubik !important;
  font-weight: 500 !important;
  color: #2a2a2a !important;
`

export const headerCss = css`
  margin-left: 200px !important;
  margin-top: 30px !important;
  font-size: 24px !important;
  ${titleCss}
`

export const connText = css`
  text-align: center;
  ${text}
`

export const instructionsCss = css`
  margin: 30px 65px 0px 65px !important;
  ${text}
`

export const BottomBlock = css`
  box-sizing: border-box;
  height: 74px;
  border-top: 1px solid #d7d7d7;
  background-color: #ffffff;
  margin: 0 auto;
  text-align: center;
`

export const changeAccountBtn = css`
  width: 180px !important;
  display: inline-block !important;
  margin: 20px;
`

export const cancelBtn = css`
  display: inline-block;
  margin: 20px;
  width: 180px !important;
  text-align: right;
  padding-right: 20px;
  color: #626262;
  font-family: Rubik;
  font-size: 16px;
`

export const arrowLeft = css({
  width: 20,
  height: 20,
  marginRight: 10,
  verticalAlign: '-0.3rem',
})
