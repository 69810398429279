export const ConnectorType = {
  None: 0,
  QBO: 1,
}

export const IntegrationType = {
  None: 0,
  MHD: 1,
  BMT: 2,
}

export const IntegrationStatus = {
  None: 0,
  NeedSetup: 1,
  ReadyToSync: 2,
  SyncInProgress: 3,
  NeedNewAccessToken: 4,
  Error: 5,
  NeedChartOfAccounts: 6,
  SyncCompleted: 7,
}

export const SyncSessionStatus = {
  None: 0,
  Ready: 1,
  ConnectorImportCompleted: 2,
  MhdImportCompleted: 3,
  MhdExportCompleted: 4,
  ConnectorExportCompleted: 5,
  Completed: 6,
  CompletedWithError: 7,
  StoppedWithError: 8,
}

export const Paths = {
  Home: '/',
  Setup: '/setup',
  QuickBooksCallback: '/quickBooksCallback',
  ConfirmConnection: '/confirm-connection',
  ChartOfAccounts: '/chartOfAccountsSetup',
  Conflicts: '/conflicts',
  Logs: '/logs',
  Preferences: '/preferences',
  AccountConnection: '/account-connection',
  EditChartOfAccounts: '/chartofaccounts',
}
