/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {Semantic} from '@mhd/components-library'
import * as styles from '../../styles'
import {AccountingContext} from '@app/context/accountingContext'
import {IntegrationStatus} from '@app/models/constants'
export interface MenuItemProps {
  redirectPath: string
  pathGroup: string[]
  menuItemName: string
  firstMenuItem?: boolean
  defaultImage?: string
  selectedImage?: string
  disabledImage?: string
}

const MenuItem = (props: MenuItemProps): JSX.Element => {
  const {activeIntegration} = useContext(AccountingContext)

  const redirect = (path: string): void => {
    window.location.href = path
  }

  const tokens = window.location.pathname.split('/')
  const selectedTab = '/' + tokens[tokens.length - 1]
  const syncInProgress =
    activeIntegration &&
    activeIntegration.integrationStatusId === IntegrationStatus.SyncInProgress
  return (
    <Semantic.Menu.Item
      as="a"
      css={[
        props.firstMenuItem ? styles.firstMenuItemCss : styles.sideMenuItemCss,
        syncInProgress
          ? styles.disabledTabCss
          : props.pathGroup.includes(selectedTab)
          ? styles.selectedTabCss
          : styles.menuTabCss,
      ]}
      onClick={(event, itemProps) => redirect(props.redirectPath)}
      disabled={syncInProgress}
    >
      <Semantic.Image
        css={styles.sideBarIconCss}
        src={
          syncInProgress
            ? props.disabledImage
            : props.pathGroup.includes(selectedTab)
            ? props.selectedImage
            : props.defaultImage
        }
      />
      {props.menuItemName}
    </Semantic.Menu.Item>
  )
}

export default MenuItem
