/** @jsx jsx */
import {jsx} from '@emotion/core'
import QuickBooksLogin from './components/quickBooksLogin/index'
import {useContext} from 'react'
import OAuthClient from 'intuit-oauth'
import {AccountingContext} from '@app/context/accountingContext'
import {Redirect} from 'react-router'
import {Paths, IntegrationStatus} from '@app/models/constants'
import {Image} from 'semantic-ui-react'
import connectArrows from 'Assets/images/app/connect-arrows.svg'
import * as styles from './styles'

const Setup = (): JSX.Element => {
  const {settings, activeIntegration, text} = useContext(AccountingContext)

  const redirectToQuickbooks = (): void => {
    const oauthClient = new OAuthClient({
      clientId: settings && settings.qbClientId,
      clientSecret: '',
      environment: settings && settings.qbEnvironment,
      redirectUri: settings && settings.qbRedirectUri,
    })

    const authUri = oauthClient.authorizeUri({
      scope: [
        OAuthClient.scopes.Accounting,
        OAuthClient.scopes.OpenId,
        OAuthClient.scopes.Email,
      ],
      state: 'mHelpDeskConnectState',
    })

    window.location.href = authUri
  }

  let content = <div />

  content = (
    <div css={styles.parentDivCss}>
      <div css={styles.topBlockCss}>
        <div css={styles.headerCss}>Connect to {text.ConnectorAppName}</div>
        <div css={styles.ovalCss}>
          <Image css={styles.iconCss} src={connectArrows} />
        </div>
      </div>
      <p css={styles.setupTextCss}>
        We need to setup the integration between {text.IntegrationAppName} and
        your accounting system. <br />
        Click the{' '}
        <span css={styles.textCss}>
          {' '}
          <b>Connect </b>
        </span>
        button to allow access to your {text.ConnectorAppName} account .
      </p>
      <div css={styles.buttonCss}>
        <QuickBooksLogin redirectToQuickbooks={redirectToQuickbooks} />
      </div>
    </div>
  )

  return activeIntegration &&
    activeIntegration.integrationId &&
    activeIntegration.integrationStatusId !== IntegrationStatus.NeedSetup ? (
    <Redirect to={Paths.Home} />
  ) : (
    content
  )
}

export default Setup
